import React, { useRef, useState, useEffect } from 'react';
import UseInterval from '../../hooks/UseInterval.hook';
import * as el from './Carousel.styled';
import { HR } from '../shared-styled-components/globals.styled';
import { generateReactKey } from '../../utils/base';
import CarouselSlide from '../CarouselSlide/CarouselSlide.component';
import { FormattedMessage } from 'react-intl';
import { Heading } from '../shared-styled-components/typography';

export default function Carousel(props) {
    console.log('OOOO', props);
  const carouselRef = useRef(null);
  const totalSlides = props.slides.length;

  const [currentSlide, setSlide] = useState(0);
  const [timerIsRunning, setTimerIsRunning] = useState(7000);
  const [progressBar, setProgressBar] = useState(0);


  UseInterval(() => handleNextClick(), timerIsRunning);

  useEffect(() => {
    initListeners();
  }, []);

  const handlePreviousClick = () => {
     setProgressBar('reset');
     currentSlide === 0 ? setSlide(totalSlides - 1) : setSlide(currentSlide - 1);
     setTimeout(function(){
       setProgressBar('animate');
     }, 10);
     setTimerIsRunning(7000);
  };

  const handleNextClick = () => {
      setProgressBar('reset');
      currentSlide === totalSlides - 1 ? setSlide(0) : setSlide(currentSlide + 1);

      setTimeout(function(){
          setProgressBar('animate');
      }, 10);
      setTimerIsRunning(7000);
  };

  const initListeners = () => {
    carouselRef.current.addEventListener('mouseenter', () =>
      setTimerIsRunning(null)
    );
    carouselRef.current.addEventListener('mouseleave', () =>
      setTimerIsRunning(7000)
    );
    setTimeout(function(){
        setProgressBar('animate');
        //console.log('resetProgressBar',resetProgressBar);
    }, 10);
  };

  let xDown = null;
  let yDown = null;

  const getTouches = (evt) => {
      return evt.touches ||             // browser API
          evt.originalEvent.touches; // jQuery
  }

  const handleTouchStart = (evt) => {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
  };

  const handleTouchMove = (evt) => {
        if ( ! xDown || ! yDown ) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                /* left swipe */
                console.log('left');
                handleNextClick();
            } else {
                /* right swipe */
                console.log('right');
                handlePreviousClick();
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    };

    const getImgLang = (slide) => {
        let bgImg = slide.bgImgEn;
        if(props.locale === 'fr' && slide.hasOwnProperty('bgImgFr')) {
            bgImg = slide.bgImgFr;
        }
        return bgImg;
    }

    const getLangHref = (slide) => {
        let href = slide.cta;
        if(props.locale === 'fr' && slide.hasOwnProperty('ctaFr')) {
            href = slide.ctaFr;
        }
        return href;
    }

  return (
    <>
      <el.CarouselGrid>
        <el.CarouselContainer ref={carouselRef} onTouchStart={(evt) => handleTouchStart(evt)} onTouchMove={(evt) => handleTouchMove(evt)}>
          <el.NavControlsOverlay>
            <el.PhotosContainer>
              {props.slides.map((slide, i) => (
                  <CarouselSlide
                      key={generateReactKey(`slide_`, i)}
                      bgImg={getImgLang(slide)}
                      ctaLink={slide.ctaLink}
                      href={getLangHref(slide)}
                      show={currentSlide === i}
                      locale={props.locale}
                  >
                      {slide.heading ? (
                          <Heading large bold color="#fff" margin="0">
                              <FormattedMessage id={slide.heading} />
                          </Heading>
                      ) : null}
                      {slide.subHeading ? (
                          <Heading medium color="#fff" margin="0 0 10px 0">
                              <FormattedMessage id={slide.subHeading} />
                          </Heading>
                      ) : null}
                  </CarouselSlide>
              ))}
            </el.PhotosContainer>
            <el.ButtonContainer left onClick={() => handlePreviousClick()}>
              <el.ArrowIcon left="-20px" />
            </el.ButtonContainer>
            <el.ButtonContainer right onClick={() => handleNextClick()}>
              <el.ArrowIcon right="-20px" />
            </el.ButtonContainer>
          </el.NavControlsOverlay>
        </el.CarouselContainer>
        <el.CarouselInfoContainer color={props.color}>
          {currentSlide + 1}
          <el.ProgressBarContainer>
            <HR className={progressBar === 'animate' ? "animate" : ""} />
          </el.ProgressBarContainer>
          {totalSlides}
        </el.CarouselInfoContainer>
      </el.CarouselGrid>
    </>
  );
}
