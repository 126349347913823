import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

// import FeaturedArticles from '../components/featureSections/FeaturedArticles/FeaturedArticles.component';
// import FeaturedRecruiting from '../components/featureSections/FeaturedRecruiting/FeaturedRecruiting.component';
import HomeIntro from '../components/home/HomeIntro.component';

// import FeaturedEvents from '../components/featureSections/FeaturedEvents/FeaturedEvents.component';

const pageName = {
  'en': 'Home',
  'fr': 'Accueil'
}

const IndexPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title={pageName[locale]} lang={locale} keywords={[`gatsby`, `application`, `react`]} />
    <HomeIntro locale={locale} />
  </Layout>
);

export default IndexPage;
