import React from 'react';
import { FormattedMessage } from 'react-intl';
import CTALink from '../CTALink/CTALink.component';
import * as el from './CarouselSlide.styled';
// import { Heading } from '../shared-styled-components/typography';

export default function CarouselSlide(props) {
  return (
    <el.CarouselSlideContainer bgImg={props.bgImg} show={props.show} href={props.href} target="_blank">
      <el.InfoContainer showBg={props.children ? true : false}>
        {props.children ? (
          <el.FlexColumn>{props.children}</el.FlexColumn>
        ) : null}
        {props.ctaLink ? (
          <el.CTAContainer>
            <CTALink ctaLink={props.ctaLink}>
              <FormattedMessage id={props.ctaLinkText} />
            </CTALink>
          </el.CTAContainer>
        ) : null}
      </el.InfoContainer>
    </el.CarouselSlideContainer>
  );
}
