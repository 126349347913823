import React, { Component } from 'react';
// import { GatsbyHref } from '../shared-styled-components/globals.styled';
import { FormattedMessage } from 'react-intl';
import intactLogo from '../../images/IntactLab_nobulb.svg';
import * as el from './HomeIntro.styled';
import { Heading } from '../shared-styled-components/typography';

// Carousel images
import home1 from '../../images/carousel/how-to-win-hackaton-sm.jpg';
import home1Lg from '../../images/carousel/how-to-win-hackaton.jpg';
import home2 from '../../images/carousel/ai-people-sm.jpg';
import home2Lg from '../../images/carousel/ai-people.jpg';
import home3 from '../../images/carousel/data-better-idea-sm.jpg';
import home3Lg from '../../images/carousel/data-better-idea.jpg';
import home4 from '../../images/carousel/hiring.png';
import home4Lg from '../../images/carousel/hiring.png';
import home4Fr from '../../images/carousel/hiring-fr.png';
import home4LgFr from '../../images/carousel/hiring-fr.png';

import Carousel from '../Carousel/Carousel.component';
import { branding } from '../../constants/css';

// function CareerUrl(locale) {
//   let careerLink = 'https://careers.intact.ca/ca/en/c/intact-lab-jobs';
//   if(locale.locale === 'fr') {
//     careerLink = 'https://careers.intact.ca/ca/fr/c/intact-lab-jobs';
//   }
//   return (
//     <>
//       <GatsbyHref to="/page-2" href={careerLink} target="_blank">
//         <FormattedMessage id="HomeIntro.BottomContent.link" />
//       </GatsbyHref>
//     </>
//   );
// }

export default class HomeIntro extends Component {

    constructor(props) {
        super(props);
        this.state = {
          offset: 0,
        };

        this.carouselData = [];

        let img1 = home1,
          img2 = home2,
          img3 = home3,
          img4 = home4,
          img4Fr = home4Fr;

        if (typeof window !== 'undefined') {
            //console.log('window is defined');

            if (window.innerWidth > 600 ) {

                //console.log('desktop version');
                img1 = home1Lg;
                img2 = home2Lg;
                img3 = home3Lg;
                img4 = home4Lg;
                img4Fr = home4LgFr;
            }

            this.carouselData = [
                {
                    bgImgEn: img1,
                    heading: 'HomeIntro.Carousel.slide1.heading',
                    subHeading: 'HomeIntro.Carousel.slide1.subheading',
                    ctaUrl: '/home/',
                    cta: 'https://medium.com/intactlab/how-to-win-a-hackathon-2e4c6dec88d9',
                },
                {
                    bgImgEn: img2,
                    heading: 'HomeIntro.Carousel.slide2.heading',
                    subHeading: 'HomeIntro.Carousel.slide2.subheading',
                    ctaUrl: '/home/',
                    cta: 'https://medium.com/intactlab/the-future-of-ai-is-people-ab75091dadee',
                },
                {
                    bgImgEn: img3,
                    heading: 'HomeIntro.Carousel.slide3.heading',
                    subHeading: 'HomeIntro.Carousel.slide3.subheading',
                    ctaUrl: '/home/',
                    cta: 'https://medium.com/intactlab/data-innovation-insurance-you-bet-aab498d4bb9c',
                },
                {
                    bgImgEn: img4,
                    bgImgFr: img4Fr,
                    heading: 'HomeIntro.Carousel.slide4.heading',
                    subHeading: 'HomeIntro.Carousel.slide4.subheading',
                    ctaUrl: '/home/',
                    cta: 'https://careers.intact.ca/ca/en/c/intact-lab-jobs',
                    ctaFr: 'https://careers.intact.ca/ca/fr/c/intact-lab-jobs',
                }
            ];
        }
    }

    componentDidMount() {
      // window.addEventListener('touch', this.parallaxShift);

      window.addEventListener('touchstart', this.handleTouchStart, false);
      window.addEventListener('touchmove', this.handleTouchMove, false);

      this.xDown = null;
      this.yDown = null;
      this.moveDistance = window.innerHeight - 100;
    }

    componentWillUnmount() {
      // window.removeEventListener('touch', this.parallaxShift);

      window.removeEventListener('touchstart', this.handleTouchStart);
      window.removeEventListener('touchmove', this.handleTouchMove);
    }

    getTouches = (evt) => {
        return evt.touches ||             // browser API
            evt.originalEvent.touches; // jQuery
    }

    handleTouchStart = (evt) => {
        const firstTouch = this.getTouches(evt)[0];
        this.xDown = firstTouch.clientX;
        this.yDown = firstTouch.clientY;
    };

    handleTouchMove = (evt) => {
        if ( ! this.xDown || ! this.yDown ) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = this.xDown - xUp;
        var yDiff = this.yDown - yUp;

        if ( Math.abs( yDiff ) > Math.abs( xDiff ) ) {/*most significant*/
            if ( yDiff > 0 ) {
                /* up swipe */
                console.log('up');

                document.getElementById('woooo').style.marginTop='-' + this.moveDistance + 'px';
            } else {
                /* down swipe */
                console.log('down');
                document.getElementById('woooo').style.marginTop='0';
            }
        }
        /* reset values */
        this.xDown = null;
        this.yDown = null;
    };

    parallaxShift = () => {
      // older way
    };

    render() {
        return (
          <>
            <el.HomeIntroGrid id="woooo">
              <el.IntroCopyContainer>
                <el.TopContent>
                  <el.IntactLogo src={intactLogo} />
                  <el.PoweredBy>
                      <FormattedMessage id="HomeIntro.IntroCopy.poweredBy" />
                  </el.PoweredBy>
                  <Heading as="h2" light large margin="0 0 40px 0">
                    <FormattedMessage id="HomeIntro.IntroCopy.heading" />
                    <FormattedMessage id="HomeIntro.IntroCopy.heading2" />
                  </Heading>
                </el.TopContent>
                {/*<el.BottomContent>*/}
                {/*  <HR width="100px" margin="0 0 15px 0" />*/}
                {/*  <Heading as="h4" capitalized small margin="0 0 10px 0">*/}
                {/*    <FormattedHTMLMessage id="HomeIntro.BottomContent.heading" />*/}
                {/*  </Heading>*/}
                {/*  <CareerUrl locale={this.props.locale} />*/}
                {/*</el.BottomContent>*/}
              </el.IntroCopyContainer>
              <el.HeroImageContainer>
                <Carousel slides={this.carouselData} color={branding.secondary} locale={this.props.locale} />
              </el.HeroImageContainer>
              <el.BackgroundUnderlay />
            </el.HomeIntroGrid>
          </>
        );
    }
}
