import styled from 'styled-components';
import * as vars from '../../constants/css';

export const HomeIntroGrid = styled.section`
  display: grid;
  display: -ms-grid;
  width: 100%;
  height: calc(200vh - 100px);
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-rows: repeat(10, 1fr);
  -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  overflow: scroll;
  -ms-overflow-style: none;


  /* TODO - Make the snapping work with Styled Components */
  /* https://jsfiddle.net/amojpsvk/3/ */
  /* https://stackoverflow.com/questions/54784885/css-scroll-snap-isnt-working-on-divs-in-react-app */
  /* perhaps because of grid and container height https://jsfiddle.net/famocx9q/23/ */
  @media (max-width: ${vars.breakpoints.md}) {
    scroll-snap-type: y mandatory;
    scroll-padding-top: 30px;
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    -webkit-transition: margin 1s; /* Safari prior 6.1 */
    transition: margin 1s;
  }
  @media (min-width: ${vars.breakpoints.md}) {
    height: 90vh;
    grid-template-rows: repeat(12, 1fr);
    -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 100px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const IntactLogo = styled.img`
  margin-bottom: 4px;
`;

export const PoweredBy = styled.div`
  font-size: 1;
  color: #656565;
  margin-bottom: 24px;
`;

export const IntroCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px 10px 16px;
  grid-row: 2 / span 2;
  -ms-grid-row: 2;
  -ms-grid-row-span: 2;
  public
  line-height: 1.2;

  @media (max-width: ${vars.breakpoints.md}) {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  
  @media (min-width: ${vars.breakpoints.md}) {
    padding: 0;
    grid-column: 2 / span 4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-row: 2 / span 12;
    -ms-grid-row-span: 2;
    -ms-grid-row-span: 12;
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    grid-column: 2 / span 4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-row: 3 / span 9;
    -ms-grid-row: 3;
    -ms-grid-row-span: 9;
  }

  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 2 / span 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 3;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    @media (min-width: ${vars.breakpoints.md}) {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
    @media (min-width: ${vars.breakpoints.lg}) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    span {
        display: block;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
  }
  a {
    font-weight: bold;
  }
`;

export const TopContent = styled.div``;
export const BottomContent = styled.div``;

export const HeroImageContainer = styled.div`
  background: url(${p => p.bgImg}) center center no-repeat;
  background-size: cover;
  grid-row: 6 / span 5;
  -ms-grid-row: 6;
  -ms-grid-row-span: 5;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: ${vars.breakpoints.md}) {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    height: calc(100vh - 100px);
  }
    
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 7 / span 5;
    -ms-grid-column: 4;
    -ms-grid-column-span: 5;
    grid-row: 4 / span 13;
    -ms-grid-row: 1;
    -ms-grid-row-span: 7;
    margin-top: -167px;
  }

  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 6 / span 6;
    -ms-grid-column: 4;
    -ms-grid-column-span: 4;
  }
`;

export const BackgroundUnderlay = styled.div`
  position: relative;
  display: none;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 8 / span 5;
    -ms-grid-column: 8;
    -ms-grid-column-span: 5;
    grid-row: 2 / span 11;
    -ms-grid-row: 2;
    -ms-grid-row-span: 11;
    background-color: ${vars.branding.paleGreen};
    z-index: 1;
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
    background-color: transparent;
    position: absolute;
  }
`;

export const HeroInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  padding: 25px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0.5) 87%,
    rgba(0, 0, 0, 0.65) 100%
  );
  @media (min-width: ${vars.breakpoints.md}) {
    padding: 10px;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    flex-direction: row;
    padding: 25px;
  }
`;

const HeroTextConstants = `
position: relative;
z-index: 10;
font-size: 3rem;
font-weight: 700;
color: ${vars.branding.secondary};
transition: all 2s ease-out;

@media (min-width: ${vars.breakpoints.md}) {
  font-size: 4.5rem;
}
`;

export const HeroText1 = styled.h4`
  ${HeroTextConstants}
  grid-row: 1 / span 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${vars.branding.secondary};
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 2 / span 11;
    -ms-grid-column: 2;
    -ms-grid-column-span: 11;
  }
  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 4 / span 9;
    -ms-grid-column: 4;
    -ms-grid-column-span: 9;
  }
`;

export const HeroText2 = styled.h4`
  ${HeroTextConstants}
  grid-row: 3 / span 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 3 / span 8;
    -ms-grid-column: 3;
    -ms-grid-column-span: 8;
    grid-row: 3 / span 2;
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;
  }
  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 7 / span 5;
    -ms-grid-column: 7;
    -ms-grid-column-span: 5;
  }
`;

export const HeroTextGrid = styled.div`
  display: grid;
  display: -ms-grid;
  width: 100%;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-rows: repeat(4, 50px);
  -ms-grid-rows: 50px 50px 50px 50px;
  overflow: hidden;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-template-columns: 100px repeat(10, 1fr) 100px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const HeroTextUnderlay = styled.div`
  background-color: ${vars.branding.paleGreen};
  grid-column: 8 / span 5;
  -ms-grid-column: 8;
  -ms-grid-column-span: 5;
  grid-row: 1 / span 4;
  -ms-grid-row: 1;
  -ms-grid-row-span: 4;
`;
