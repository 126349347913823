import styled, { css } from 'styled-components';
import * as vars from '../../constants/css';
import arrowIcon from '../../images/arrow.svg';
import arrowHoverIcon from '../../images/arrow_hover.svg';

export const CarouselGrid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  display: -ms-grid;
  grid-template-rows: 1fr 80px;
  -ms-grid-rows: 1fr 80px;
  -ms-grid-columns: 1fr 80px;
`;

export const CarouselContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const NavControlsOverlay = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
    top: 50%;
    left: 50%;
  }
`;

export const ButtonContainer = styled.button`
  height: 100%;
  width: 100px;
  z-index: 10;
  border: none;
  background: rgba(0, 0, 0, 0);
  display: flex;
  ${p =>
    p.left &&
    css`
      justify-content: flex-start;
      div {
        transform: scaleX(-1);
      }
    `};

  ${p =>
    p.right &&
    css`
      justify-content: flex-end;
    `};

  &:hover {
    cursor: pointer;
  }
  
  &:focus {
    outline: none;  
    div {
      background: url(${arrowHoverIcon}) center center no-repeat
      ${vars.branding.secondaryDark};
    }
  }
`;

export const ArrowIcon = styled.div`
  background: #fff;
  height: 40px;
  width: 60px;
  margin: 0;
  top: calc(50% - 20px);
  position: absolute;
  background: url(${arrowIcon}) center center no-repeat ${vars.branding.primary};
  background-size: 30%;
  transition: background 200ms ease-out;
  @media (min-width: ${vars.breakpoints.md}) {
    height: 40px;
    width: 40px;
    left: ${p => (p.left ? p.left : 'auto')};
    right: ${p => (p.right ? p.right : 'auto')};
  }
  &:active {
    opacity: 0.5;
  }
`;

export const PhotosContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
   top: -50%;
   left: -50%;
  }
`;

export const PhotoDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${p => p.imgSrc}) center center no-repeat
    ${vars.branding.grey1};
  background-size: cover;
  transition: opacity 500ms ease-out;
  opacity: ${p => (p.show ? 1 : 0)};
`;

export const CarouselInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => (p.color ? p.color : vars.branding.secondary)};
  @media (min-width: ${vars.breakpoints.md}) {
    margin: 0;
    justify-content: flex-end;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
   position: absolute;
   top: 90%;
   right: 20%;
   width: 300px;
  }
`;

export const ProgressBarContainer = styled.div`
  margin: 0 10px;
  display: inline-block;
  width: 30%;
  hr {
    width: 0;
    margin: 0;
    height: 2px;
    background: #208083;
    &.animate {
      width: 100%;
        -webkit-transition: width 7s linear;
        -moz-transition: width 7s linear;
        -o-transition: width 7s linear;
        transition: width 7s linear;
        transition-delay: 0.25s;
    }
  }
`;

